import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/IMG1.png'
import IMG2 from '../../assets/IMG2.png'
import IMG3 from '../../assets/IMG3.png'
import IMG4 from '../../assets/IMG4.png'
import IMG5 from '../../assets/IMG5.png'
import IMG6 from '../../assets/IMG6.png'

const data = [
  {
    id: 1,
    image: IMG1,
    title: 'Entwicklung eines Webshops',
    website: 'https://www.cleanairtech.at/',
    review: '#testimonials', 
  },
  {
    id: 2,
    image: IMG2,
    title: 'Riesige Inhalte - Kein Problem',
    website: 'https://www.notar-stefan.at/',
    review: '#testimonials', 
  },
  {
    id: 3,
    image: IMG3,
    title: 'Website von Scratch',
    website: 'https://innotech-electronic.de/',
    review: '#testimonials', 
  },
  {
    id: 4,
    image: IMG4,
    title: 'Der erste digitale Auftritt',
    website: 'https://adlerapothekesimmering.at/',
    review: '#testimonials', 
  },
  {
    id: 5,
    image: IMG5,
    title: 'Makelloses ReDesign ',
    website: 'http://www.consys.at/',
    review: '#testimonials', 
  },
  {
    id: 6,
    image: IMG6,
    title: 'Bald kommt Etwas tolles...',
    production: 'https://www.sytech.at/',
  },
]

const Portolio = () => {
  return (
        <section id='portfolio'>
            <h5>Meine neuste Arbeit</h5>
            <h2>Portfolio</h2>

            <div className="container portfolio_container">
                {
                  data.map(({id, image, title, website, review}) => {
                    return (
                      <article key={id} className="portfolio_item">
                    <div className="portfolio_item-image">
                        <img src={image} alt={title} />
                    </div>
                    {/* <h3>{title}</h3> */}
                    <div className="portfolio_item-cta">
                    {/* <a href={website} className="btn" rel="noreferrer" target='_blank'>Zum Projekt</a>
                    <a href={review} className="btn btn-primary">Kundenreferenz</a> */}
                    </div>
                </article> 
                    )
                  })
                }     
            </div>
            <div className='container portfolio_item-lead'>
              <a href='https://www.webartist-design.at/referenzen/' className="btn-portfolio" rel="noreferrer" target='_blank'>Zur Portfolioübersicht</a>
              <a href='#contact' className="btn btn-primary">Jetzt Ihr Projekt besprechen</a>
            </div>
        </section>
  )
}

export default Portolio