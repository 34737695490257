import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {HiOutlineMail} from 'react-icons/hi'
import {BsInstagram} from 'react-icons/bs'

const HeaderSocials = () => {
  return (
    <div className='header_socials'>
        <a href="https://www.linkedin.com/in/thomas-naschenweng-93b262222/" target="_blank"><BsLinkedin/></a>
        <a href="https://www.instagram.com/thomas__3008/" target="_blank"><BsInstagram/></a>
        <a href="mailto:thomas@webartist-design.at?subject=Hallo Thomas" target="_blank"><HiOutlineMail/></a>
    </div>
  )
}

export default HeaderSocials