import React from 'react'
import '../../components/testimonials/testimonials.css'
import AVTR1 from '../../assets/Barbara-Koenigsberger.png'
import AVTR2 from '../../assets/avatar1.jpg'
import AVTR3 from '../../assets/avatar2.jpg'

// import Swiper core and required modules
import { FreeMode, Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';



const data = [
  {
    avatar: AVTR1,
    name: 'Babara Königsberger',
    role: 'CTO VacuMIT Maschinenbau GmbH',
    review: 'Sehr gute Zusammenarbeit, Preisleistungsverhältnis ist TOP, kann jederzeit weiterempfehlen, Betreuung sehr gut.'
  },
  {
    avatar: AVTR2,
    name: 'Andreas Winter',
    role: 'CEO Sytech Andreas Winter GmbH',
    review: 'Super Service, meine HPs wurden immer pünktlich und wie besprochen umgesetzt. Sehr freundlich und vor allem verlässlich! Kann ich nur jedem empfehlen.'
  },
  {
    avatar: AVTR3,
    name: 'Dipl.-Ing. Heiko Petzold',
    role: 'CEO Innotech electronic GmbH',
    review: 'Top Arbeit, schnell in der Umsetzung! Auch ich als Laie wurde sehr geduldig beraten! LG Heiko Innotech electronic'
  }
]

const testimonials = () => {
  return (
    <section id='testimonials'>
    <h5>Bewertungen von Kunden</h5>
    <h2>Kundenreferenz</h2>

    <Swiper className='container testimonials_container'
    // install Swiper modules
    modules={[Pagination, FreeMode]}
    spaceBetween={40}
    slidesPerView={1}
    // freeMode={true}
    // loop
    pagination={{ clickable: true }}
    >
      {
        data.map(({avatar, name, role, review}, index) => {
          return (
            <SwiperSlide key={index} className='testimonial'>
              <div className='client_avatar'>
                 <img src={avatar} alt="Avatar One" />
             </div>
                <h5 className='client_name'>{name}</h5>
                <h6 className='client_role'>{role}</h6>
                <small className='client_review'>{review}</small>
            </SwiperSlide>
          )
        })
      }
    </Swiper>
    </section>
  )
}


export default testimonials