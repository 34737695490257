import React from 'react'
import './contact.css'
import {MdOutlineMail} from 'react-icons/md'
import {RiMessengerLine} from 'react-icons/ri'
import {FaWhatsapp} from 'react-icons/fa'
import { useRef } from 'react';
import emailjs from 'emailjs-com';

export const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_qp0eg9f', 'template_8crhufd', form.current, 'M88114FRMUdJlgMc8')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

      e.target.reset()
  };

  return (
    <section id='contact'>
      <h5>Schreiben Sie mir</h5>
      <h2>Kontakt</h2>

      <div className='container contact_container'>
        <div className='contact_options'>
          <article className='contact_option'>
            <MdOutlineMail className='contact_option-icon'/>
            <h4>Email</h4>
            <h5>thomas@webartist-design.at</h5>
            <a href="mailto:thomas@webartist-design.at?subject=Hallo Thomas" target="_blank">Sende eine Nachricht</a>
          </article>

          <article className='contact_option'>
            <RiMessengerLine className='contact_option-icon' />
            <h4>Messenger</h4>
            <h5>WebArtist-Design</h5>
            <a href="https://www.facebook.com/WebArtist-Design-107100838461022/" target="_blank">Sende eine Nachricht</a>
          </article>

          <article className='contact_option'>
            <FaWhatsapp className='contact_option-icon' />
            <h4>WhatsApp</h4>
            <h5>+43 664 456 83 99</h5>
            <a href="https://wa.me/+436644568399" target="_blank">Sende eine Nachricht</a>
          </article>
        </div>
        {/* END OF CONTACT OPTIONS  */}
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Ihr Vor- und Nachname' required />
          <input type="email" name='email' placeholder='Ihre E-Mail' required />
          <textarea name="message" rows="7" placeholder='Ihre Nachricht' required></textarea>
          <button type='submit' className='btn btn-secondary'>Nachricht senden</button>
        </form>
      </div>
    </section>
  )
}

export default Contact