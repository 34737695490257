import React from 'react'
import './experience.css'
import {BsPatchCheckFill} from 'react-icons/bs'

const Experience = () => {
  return (
    <section id='experience'>      
      <h5>Meine Fähigkeiten</h5>
      <h2>Erfahrung</h2>

      <div className="container experience_container">
        <div className="experience_frontend">
          <h3>Frontend Development</h3>
          <div className="experience_content">
          <article className='experience_details'>
            <BsPatchCheckFill className='experience_details-icon' />
            <div>
              <h4>HTML</h4>
              <small className='text-light'>Experte</small>
            </div>
            </article>
            
            <article className='experience_details'>
            <BsPatchCheckFill className='experience_details-icon' />
            <div>
              <h4>CSS</h4>
              <small className='text-light'>Experte</small>
            </div>
            </article>

            <article className='experience_details'>
            <BsPatchCheckFill className='experience_details-icon' />
            <div>
              <h4>JavaScript</h4>
              <small className='text-light'>Experte</small>
            </div>
            </article>

            <article className='experience_details'>
            <BsPatchCheckFill className='experience_details-icon' />
            <div>
              <h4>React.js</h4>
              <small className='text-light'>Experte</small>
            </div>          
            </article>

            <article className='experience_details'>
            <BsPatchCheckFill className='experience_details-icon' />
            <div>
              <h4>Vue.js</h4>
              <small className='text-light'>Fortgeschritten</small>
            </div>
            </article>

            <article className='experience_details'>
            <BsPatchCheckFill className='experience_details-icon' />
            <div>
              <h4>Laravel</h4>
              <small className='text-light'>Grundkenntnisse</small>
            </div>          
            </article>

          </div>
        </div>
        {/* END FRONTEND */}

        <div className="experience_backend">
        <h3>Content Management Systeme</h3>
          <div className="experience_content">
          <article className='experience_details'>
            <BsPatchCheckFill className='experience_details-icon' />
            <div>
              <h4>WordPress</h4>
              <small className='text-light'>Experte</small>
            </div>
            </article>
            
            <article className='experience_details'>
            <BsPatchCheckFill className='experience_details-icon' />
            <div>
              <h4>Shopify</h4>
              <small className='text-light'>Experte</small>
            </div>
            </article>

            <article className='experience_details'>
            <BsPatchCheckFill className='experience_details-icon' />
            <div>
              <h4>Jimdo</h4>
              <small className='text-light'>Fortgeschritten</small>              
            </div>
            </article>

            <article className='experience_details'>
            <BsPatchCheckFill className='experience_details-icon' />
            <div>
              <h4>Gambio + cloud</h4>
              <small className='text-light'>Fortgeschritten</small>
            </div>
            </article>

            <article className='experience_details'>
            <BsPatchCheckFill className='experience_details-icon' />
            <div>
              <h4>One</h4>
              <small className='text-light'>Fortgeschritten</small>
            </div>
            </article>

            <article className='experience_details'>
            <BsPatchCheckFill className='experience_details-icon' />
            <div>
              <h4>Wix</h4>
              <small className='text-light'>Fortgeschritten</small>
            </div>
            </article>
          </div>
        </div>
      </div>



    </section>
  )
}

export default Experience