import React from 'react'
import './services.css'
import {BiCheck} from 'react-icons/bi'

const services = () => {
  return (
    <section id='services'>
      <h5>Was ich anbiete</h5>
      <h2>Services</h2>

      <div className="container services_container">
      <article className="service">
        <div className="service_head">
          <h3>Websitebetreuung</h3>
        </div> 

        <ul className='service_list'>
          <li>
            <BiCheck className='service_list-icon' />
            <p>Persönliche Betreuung</p>
          </li>
          <li>
            <BiCheck className='service_list-icon' />
            <p>Aktualisierung von CMS und Softwarepaketen</p>
          </li>
          <li>
            <BiCheck className='service_list-icon' />
            <p>Regelmäßige Backups</p>
          </li>
          <li>
            <BiCheck className='service_list-icon' />
            <p>Wöchentliche Sicherheitstest</p>
          </li>
          <li>
            <BiCheck className='service_list-icon' />
            <p>Nutzungsstatistiken auf Abruf</p>
          </li>
        </ul>
      </article>
      {/* END OF WEBETREUUNG */}
      <article className="service">
        <div className="service_head">
          <h3>Webentwicklung</h3>
        </div> 

        <ul className='service_list'>
          <li>
            <BiCheck className='service_list-icon' />
            <p>Persönliche Betreuung</p>
          </li>
          <li>
            <BiCheck className='service_list-icon' />
            <p>Faire Preise und schnelle Arbeit</p>
          </li>
          <li>
            <BiCheck className='service_list-icon' />
            <p>Modernste Applikationen und Design</p>
          </li>
          <li>
            <BiCheck className='service_list-icon' />
            <p>Innovatives Design</p>
          </li>
          <li>
            <BiCheck className='service_list-icon' />
            <p>Intensive Konzeptphase</p>
          </li>
          <li>
            <BiCheck className='service_list-icon' />
            <p>Responsive für alle Systeme</p>
          </li>
          <li>
            <BiCheck className='service_list-icon' />
            <p>Suchmaschinenoptimiert</p>
          </li>
          <li>
            <BiCheck className='service_list-icon' />
            <p>Cyber secured</p>
          </li>
          <li>
            <BiCheck className='service_list-icon' />
            <p>24/7 Support</p>
          </li>
        </ul>
      </article>
      {/* END OF WEBENTWICKLUNG */}
      <article className="service">
        <div className="service_head">
          <h3>Webhosting</h3>
        </div> 

        <ul className='service_list'>
          <li>
            <BiCheck className='service_list-icon' />
            <p>Persönliche Betreuung</p>
          </li>
          <li>
            <BiCheck className='service_list-icon' />
            <p>Günstige Tarife</p>
          </li>
          <li>
            <BiCheck className='service_list-icon' />
            <p>Server direkt in Österreich und Deutschland</p>
          </li>
          <li>
            <BiCheck className='service_list-icon' />
            <p>Geringer und unlimitierter Traffic</p>
          </li>
          <li>
            <BiCheck className='service_list-icon' />
            <p>SSL zertifiziert</p>
          </li>
        </ul>
      </article>
      {/* END OF WEBHOSTING */}
      </div>  
    </section>
  )
}

export default services