import React from 'react'
import './footer.css'
import Logo from '../../assets/Logo.png'
import {FaFacebookF} from 'react-icons/fa'
import {FiInstagram} from 'react-icons/fi'
import {FaLinkedinIn} from 'react-icons/fa'


const footer = () => {
  return (
    <footer>
      <a href="https://www.webartist-design.at/" target="_blank"><img src={Logo} alt="WebArtist-Design" className='footer_logo' /></a>
      {/* <a href="#" className='footer_logo'>WebArtist</a> */}

      <ul className='permalinks'>
        <li><a href="#">Home</a></li>
        <li>|</li>
        <li><a href="#about">Über mich</a></li>
        <li>|</li>
        <li><a href="#experience">Erfahrung</a></li>
        <li>|</li>
        <li><a href="#services">Services</a></li>
        <li>|</li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li>|</li>
        <li><a href="#testimonials">Kundenreferenzen</a></li>
        <li>|</li>
        <li><a href="#contact">Kontakt</a></li>
      </ul>

      <div className='footer_socials'>
        <a href="https://www.facebook.com/WebArtist-Design-107100838461022/" target="_blank"><FaFacebookF /></a>
        <a href="https://www.instagram.com/webartist.design/" target="_blank"><FiInstagram /></a>
        <a href="https://www.linkedin.com/in/thomas-naschenweng-93b262222/" target="_blank"><FaLinkedinIn /></a>
      </div>

      <div className="footer_copyright">
        <small>&copy; WebArtist. Alle Rechte vorbehalten.</small>
      </div>
    </footer>
  )
}

export default footer