import React from 'react'
import './about.css'
import ME from '../../assets/me-aboutv04.png'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'

const About = () => {
  return (
    <section id='about'>  
    <h5>Erfahren Sie mehr</h5>
    <h2>Über mich</h2>

    <div className="container about_container">
      <div className="about_me">
          <div className="about_me-image">
            <img src={ME} alt="About Image" />
          </div>
      </div>

      <div className="about_content">
        <div className="about_cards">
          <article className='about_card'>
            <FaAward className='about_icon'/>
            <h5>Projekterfahrung</h5>
            <small>4+ Jahre</small>
          </article>

          <article className='about_card'>
            <FiUsers className='about_icon'/>
            <h5>Kunden</h5>
            <small>30+ AT und DE</small>
          </article>

          <article className='about_card'>
            <VscFolderLibrary className='about_icon'/>
            <h5>Projekte</h5>
            <small>20+</small>
          </article>
        </div>
        <p>
        Als Fullstack Developer platziere ich unsere Kunden seit 2018 optimal im Internet. Von der professionellen Website über
        Suchmaschinenoptimierung bis hin zu Logo-Design und individuellem Grafikdesign – bei WebArtist-Design sind Sie genau richtig, 
        wenn Sie Know-how, einen zuverlässigen Partner und persönliche Betreuung schätzen!
        </p>
        <a href="#contact" className='btn btn-secondary'>Jetzt Ihr Projekt besprechen</a>
      </div>
    </div>
    </section>
  )
}

export default About